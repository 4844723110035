<template>
  <!-- 销售只能看自己的业绩 -->
  <sale-monthly-performance-self-list v-if="isSale" />
  <sale-monthly-performance-admin-list v-else />
</template>

<script>
import SaleMonthlyPerformanceAdminList from '@/views/sale_monthly_performances/admin'
import SaleMonthlyPerformanceSelfList from '@/views/sale_monthly_performances/sale'
export default {
  name: 'SaleMonthlyPerformanceIndex',
  components: {
    SaleMonthlyPerformanceSelfList,
    SaleMonthlyPerformanceAdminList
  },
  computed: {
    // 是否是销售
    isSale() {
      return this.$store.getters.userRole === 'sale' || this.$store.getters.userRole === 'sale_assistant'
    }
  }
}
</script>
