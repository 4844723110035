<template>
  <div>
    <search-sale-monthly-performance @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >

      <span slot="additional_card_amount_title">
        <a-tooltip>
          <template slot="title">
            指采购账单中卡板费用之和
          </template>
          新增业绩-卡板(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="additional_product_amount_title">
        <a-tooltip>
          <template slot="title">
            指采购账单中套餐费用之和
          </template>
          新增业绩-套餐(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="stored_amount_title">
        <a-tooltip>
          <template slot="title">
            指非新卡产生的业绩，如续订账单、终端订购账单
          </template>
          存量业绩(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="new_agents_count_title">
        <a-tooltip>
          <template slot="title">
            指在对应月份产生了账号创建后的第一笔账单的客户，并且账单有对应的责任销售
          </template>
          新增客户数
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="total_agents_count_title">
        <a-tooltip>
          <template slot="title">
            指在对应月份产生了销售业绩的客户
          </template>
          客户总数
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <div slot="month" slot-scope="month, record">
        <router-link :to="{ name: 'sale_monthly_performance_info', params: { id: record.id }}">
          {{ month }}
        </router-link>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchSaleMonthlyPerformance from '@/views/sale_monthly_performances/sale/Search'
import { findSaleMonthlyPerformances, exportSaleMonthlyPerformances } from '@/api/sale_monthly_performance'
import { formatFloat } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'SaleMonthlyPerformanceSelfList',
  components: {
    SearchSaleMonthlyPerformance,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '月份',
          dataIndex: 'month',
          sorter: true,
          sortOrder: this.sort.sort_field === 'month' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'month' }
        },
        {
          title: '销售业绩总额(元)',
          customRender: formatFloat,
          sorter: true,
          sortOrder: this.sort.sort_field === 'total_amount' ? this.sort.sort_order : false,
          dataIndex: 'total_amount'
        },
        {
          slots: { title: 'additional_card_amount_title' },
          customRender: formatFloat,
          sorter: true,
          sortOrder: this.sort.sort_field === 'additional_card_amount' ? this.sort.sort_order : false,
          dataIndex: 'additional_card_amount'
        },
        {
          slots: { title: 'additional_product_amount_title' },
          customRender: formatFloat,
          sorter: true,
          sortOrder: this.sort.sort_field === 'additional_product_amount' ? this.sort.sort_order : false,
          dataIndex: 'additional_product_amount'
        },
        {
          slots: { title: 'stored_amount_title' },
          customRender: formatFloat,
          sorter: true,
          sortOrder: this.sort.sort_field === 'stored_amount' ? this.sort.sort_order : false,
          dataIndex: 'stored_amount'
        },
        {
          title: '新增卡量',
          sorter: true,
          sortOrder: this.sort.sort_field === 'new_cards_count' ? this.sort.sort_order : false,
          dataIndex: 'new_cards_count'
        },
        {
          slots: { title: 'new_agents_count_title' },
          sorter: true,
          sortOrder: this.sort.sort_field === 'new_agents_count' ? this.sort.sort_order : false,
          dataIndex: 'new_agents_count'
        },
        {
          slots: { title: 'total_agents_count_title' },
          sorter: true,
          sortOrder: this.sort.sort_field === 'total_agents_count' ? this.sort.sort_order : false,
          dataIndex: 'total_agents_count'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findSaleMonthlyPerformances(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportSaleMonthlyPerformances(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

