<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="月份">
            <a-month-picker
              placeholder="请选择月份"
              v-decorator="['month']"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatYearMonth } from '@/utils/time'

export default {
  name: 'SearchSaleMonthlyPerformanceList',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'search' })
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      const values = this.form.getFieldsValue()
      this.$emit('submit', { month: formatYearMonth(values.month) })
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
