import request from '@/utils/request'

// 销售业绩列表
export function findSaleMonthlyPerformances(params) {
  return request({
    url: `/sale_monthly_performances`,
    method: 'get',
    params: params
  })
}

// 销售业绩月度详情
export function findSaleMonthlyPerformancesByMonth(params) {
  return request({
    url: `/sale_monthly_performances/by_month`,
    method: 'get',
    params: params
  })
}

// 销售业绩详情
export function findSaleMonthlyPerformance(id) {
  return request({
    url: `/sale_monthly_performances/${id}`,
    method: 'get'
  })
}

// 新增业绩
export function findSaleAgentMonthlyAdditionalPerformances(id, params) {
  return request({
    url: `/sale_monthly_performances/${id}/additional`,
    method: 'get',
    params: params
  })
}

// 存量业绩
export function findSaleAgentMonthlyStorePerformances(id, params) {
  return request({
    url: `/sale_monthly_performances/${id}/stored`,
    method: 'get',
    params: params
  })
}

// 导出销售业绩列表
export function exportSaleMonthlyPerformances(data) {
  return request({
    url: `/sale_monthly_performances/export`,
    method: 'post',
    data
  })
}

// 导出销售业绩月度详情
export function exportSaleMonthlyPerformancesByMonth(data) {
  return request({
    url: `/sale_monthly_performances/by_month/export`,
    method: 'post',
    data
  })
}
